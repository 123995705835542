import React from 'react';
import Layout from "../components/layout"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import { TitleHeader, wrapper, IconButton } from "../components/defaults/styles/elements"
import PressFooter from "../components/pressfooter"
import { color } from "../components/defaults/styles/theme"
import Link from '../components/link';
import { handleDisplayLinks } from "../templates/peopleProfile"
import { People } from '../components/defaults/assets/svg/mobilenav';
import Info from '../components/defaults/assets/svg/info.svg';
import ExternalLink from '../components/defaults/assets/svg/externallink.svg';
import Download from '../components/defaults/assets/svg/download.svg';
import mq from '../components/defaults/styles/mediaquerys';
import SubNav from "../components/main/sub_nav"
import PressReleaseEN from "../../static/media/PressRelease_en.pdf"
import PressReleaseDE from "../../static/media/Pressemeldung_de.pdf"


const pressLinks = [
  {
    title: "Website",
    url: "https://www.startup-autobahn.com/",
  },
  {
    title: "LinkedIn",
    url: "https://linkedin.com/company/startupautobahn",
  },
  {
    title: "Twitter",
    url: "https://twitter.com/StartupAutobahn",
  },
  {
    title: "Instagram",
    url: "https://instagram.com/startupautobahn/",
  },
]


const downloads = [
  {
    title: "Press Release EN",
    link: PressReleaseEN,
    type: "download",
  },
  {
    title: "Pressemeldung DE",
    link: PressReleaseDE,
    type: "download",
  },
  {
    title: "Photographs",
    link: "https://pictures.startup-autobahn.com",
    type: "link",
  },
  {
    title: "Logos",
    link: "http://stuttgart.pnptc.design/",
    type: "link",
  },
]


const contact = [
  {
    head: "Press Contact (Englisch)",
    title: "Communication and Community Manager",
    name: "Holly Clayman",
    phone: "+49 (0) 1719122452",
    email: "holly@pnptc.com",
    profile: "/people/1654e7c6-0384-50b4-8d7d-fb0ad8db0a5a",
  },
  {
    head: "Presse Kontakt (Deutsch)",
    title: "Senior Program and Community Manager",
    name: "Hannah Boomgaarden",
    phone: "+49 (0) 15731986462",
    email: "hannah@pnptc.com",
    profile: "/people/63a942b0-396c-5f81-916d-f5e4e4070b0d",
  },
]

const nav_data = [
  {
    name: "EN",
    url: "/pressrelease/en",
  },
  {
    name: "DE",
    url: "/pressrelease/de",
  }
]

const SubTitle = ({title}) => {
  return (
    <div css={css`
      position: relative;
      margin: 5em 0 1em;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        left: 0;
        height: 1px;
        width: 100%;
        background: ${color.main_dark};
      }
    `}>
      <h3 css={css`
        background: ${color.main_light};
        position: relative;
        display: inline;
        padding-right: 1em;
      `}>
        {title}
      </h3>
    </div>
  )
}

const PressRelease = ({data}) => {
  const seo = {
    frontmatter: {
      title: data.markdownRemark.frontmatter.title,
    },
  }

  const fm = data.markdownRemark.frontmatter;
  return (
    <Layout seo={seo}>
      <TitleHeader
        title={fm.language === "de" ? "Pressemeldung" : "Press Release"}
        icon={<Info />}
        subtitle={`The official STARTUP AUTOBAHN pressrelease for EXPO 8. To find out more about STARTUP AUTOBAHN powered by Plug & Play and to download the pressrelease please scroll down to the end of this article where all additional resources are listed.`}
      />

      <div css={wrapper}>
        <SubNav
          nav_data={nav_data}
          legende="<p>The press release is available in both, german and english.</br>Feel free to choose the one that fits your needs right.</p>"
        />
      </div>

      <section
        css={css`
          background: ${color.main_light};
          padding: 8em 0;
          position: relative;
          margin-top: -2.5rem;
          p,
          a {
            color: ${color.main_dark};
          }
        `}
      >
        <div css={[wrapper, css``]}>
          <div
            css={css`
              max-width: 45rem;
              margin: 0 auto;
            `}
          >
            <p
              css={css`
                margin-bottom: 0.35em;
                font-size: 0.85em;
                font-weight: 600;
                opacity: 0.65;
              `}
            >
              {fm.date}
            </p>
            <h2
              css={css`
                font-size: 1.75em;
                line-height: 1.45em;
                margin-bottom: 0.5em;
              `}
            >
              {fm.title}
            </h2>
            <p
              css={css`
                font-size: 1.15em;
                line-height: 1.7em;
              `}
            >
              {fm.teaser}
            </p>
            <div
              css={css`
                margin: 2.5em 0 10em;
                h3 {
                  font-size: 1.2em;
                  line-height: 1.5em;
                  margin: 2.25em 0 0.5em;
                  width: 85%;
                }
                p {
                  line-height: 1.75em;
                  margin-bottom: 1.5em;
                }
              `}
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </div>

          <div>
            <SubTitle title="Downloads" />
            <ul
              css={css`
                list-style: none;
                margin: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li {
                  flex: 0 0 100%;
                  overflow: hidden;
                  ${mq[0]} {
                    flex: 0 0 calc(50% - 0.5rem);
                  }
                  ${mq[2]} {
                    flex: 0 0 calc(25% - 0.5rem);
                  }
                }
              `}
            >
              {downloads.map((el, index) => (
                <li
                  key={index}
                  css={css`
                    margin-bottom: 0.5em;
                  `}
                >
                  <Link
                    to={el.link}
                    css={[
                      css`
                        background: white;
                        border: 1px solid ${color.accent_light};
                        border-radius: 5px;
                        padding: 1em;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        height: 100%;
                        svg {
                          width: 100%;
                          height: 100%;
                        }
                        p {
                          font-size: 0.75em;
                        }
                      `,
                    ]}
                  >
                    <div
                      css={css`
                        height: 1em;
                        width: 1em;
                        line-height: 1em;
                        flex: 0 0 1em;
                      `}
                    >
                      {el.type === "link" ? <ExternalLink /> : <Download />}
                    </div>
                    <div
                      css={css`
                        flex: 0 0 auto;
                        padding-left: 1em;
                        width: 100%;
                      `}
                    >
                      <p
                        css={css`
                          font-weight: 600;
                          margin: 0;
                        `}
                      >
                        {el.title}
                      </p>
                      <p
                        css={css`
                          margin: 0;
                        `}
                      >
                        {el.type === "link" ? "External Link" : "Download"}
                      </p>
                      {el.type === "link" ? (
                        <p
                          css={css`
                            margin: 0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          `}
                        >
                          {handleDisplayLinks(el.link)}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </Link>
                </li>
              ))}
              <li
                css={css`
                  margin-bottom: 0;
                `}
              ></li>
              <li
                css={css`
                  margin-bottom: 0;
                `}
              ></li>
              <li
                css={css`
                  margin-bottom: 0;
                `}
              ></li>
            </ul>
          </div>

          <div css={css``}>
            <SubTitle title="Links" />
            <ul
              css={css`
                list-style: none;
                margin: 0;
                font-size: 0.75em;
                max-width: 50em;
              `}
            >
              {pressLinks.map((el, index) => (
                <li
                  key={index}
                  css={css`
                    margin-bottom: 0.5em;
                    p {
                      margin: 0;
                    }
                  `}
                >
                  <Link
                    to={el.url}
                    css={css`
                      text-decoration: none;
                      display: flex;
                      justify-content: space-between;
                      padding: 1em 0.5em;
                      background: white;
                      border-radius: 5px;
                      border: 1px solid ${color.accent_light};
                      padding: 1em;
                    `}
                  >
                    <p
                      css={css`
                        font-weight: 600;
                        letter-spacing: 0.02em;
                      `}
                    >
                      {el.title}
                    </p>
                    <p>{handleDisplayLinks(el.url)}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <SubTitle title="Contact" />
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            {contact.map((el, index) => (
              <ul
                key={index}
                css={css`
                  background: white;
                  border-radius: 5px;
                  border: 1px solid ${color.accent_light};
                  padding: 1em 5em 1em 1em;
                  list-style: none;
                  margin: 0;
                  font-size: 0.75em;
                  margin-bottom: .5em;
                  ${mq[1]} {
                    margin-bottom: 0;
                  }
                  &:not(:last-of-type) {
                    margin-right: 1em;
                  }
                  p {
                    margin-bottom: 0;
                  }
                  li {
                    margin-bottom: 0;
                  }
                `}
              >
                <li>
                  <p
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    {el.head}
                  </p>
                </li>
                <li>
                  <p>{el.title}</p>
                </li>
                <li>
                  <p>{el.name}</p>
                </li>
                <li>
                  <p>{el.phone}</p>
                </li>
                <li>
                  <Link to={`mailto:${el.email}`}>{el.email}</Link>
                </li>
                <IconButton
                  name="Profile"
                  icon={<People />}
                  to={el.profile}
                  css={[
                    css`
                      position: relative;
                      z-index: 1;
                      display: inline-block;
                      margin-top: 1em;
                      svg {
                        stroke: ${color.main_dark};
                      }
                      &:before {
                        background: ${color.main_light};
                        border: 1px solid ${color.accent_light};
                      }
                    `,
                  ]}
                />
              </ul>
            ))}
          </div>
        </div>
      </section>
      <PressFooter theme={color.main_light} />
    </Layout>
  )
}

export default PressRelease;


export const query = graphql`
         query pressrelease($id: String) {
           markdownRemark(id: { eq: $id }) {
             id
             fields {
               slug
             }
             frontmatter {
               language
               title
               teaser
               date(formatString: "MMMM Do, YYYY")
             }
             html
           }
         }
       `